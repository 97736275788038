import { API_HOST, API_VER } from './api_host'

export const API_PATH = function(api_endpoint) {
  return `${API_HOST}${API_VER}${api_endpoint}`
}

// Auth
export const API_SIGNIN = '/auth/signin'
export const API_SIGNUP = '/auth/signup'

// // Dashboard
export const API_DASHBOARD_INFO = '/app/dashboard'

// // Upload
// export const API_DIRECT_UPLOAD_INFO = '/app/files/upload'
// export const API_UPLOAD_UPDATE = '/app/files/update'

// // Files
// export const API_FILE_INFO      = '/app/files/info'
// export const API_UPLOAD_FILES   = '/app/files/index'
// export const API_DOWNLOAD_INFO  = '/app/files/download'

// // File Requests
// export const API_REQUEST_FILES = '/app/file_requests/index'
// export const API_REQUEST_FILE_CREATE = '/app/file_requests/create'
// export const API_REQUEST_FILE_UPDATE_STATUS = '/app/file_requests/update_status'

// // Settings
// export const API_SETTINGS_INFO = '/app/settings/info'
// export const API_SETTINGS_UPDATE = '/app/settings/update'

// // Public Request
// export const API_PUBLIC_REQUEST_INFO = '/public/requests/info'
// export const API_PUBLIC_REQUEST_UPLOAD = '/public/requests/upload'
// export const API_PUBLIC_REQUEST_UPLOAD_COMPLETE = '/public/requests/complete'