import {
  doPost
} from './_methods'

import {
  API_SIGNIN,
  API_SIGNUP,
} from '../const/api_paths'

export const signin = (data, callback) => {
  doPost(API_SIGNIN, data, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}

export const signup = (data, callback) => {
  doPost(API_SIGNUP, data, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}