const KEY = 'authToken'
const localStorage = typeof window !== 'undefined' && window.localStorage

export const setAuthToken = (authToken) => localStorage && localStorage.setItem(KEY, authToken)

export const removeAuthToken = () => localStorage && localStorage.removeItem(KEY)

export const getAuthToken = () => localStorage && localStorage.getItem(KEY)

export const hasAuthToken = () => Boolean(getAuthToken())

export const getBearerToken = () => {
  const authToken = getAuthToken()
  if (authToken) {
    return `Bearer ${authToken}`
  }
}

export const addAuthorizationHeaderToRequest = (request, next) => {
  const bearerToken = getBearerToken()
  if (bearerToken) {
    request.options.headers = request.options.headers || {}
    request.options.headers.authorization = bearerToken
  }
  if (next) {
    next()
  }
}
